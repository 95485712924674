import React from "react";
import Layout from "../components/organisms/layout/layout";
import ProjectDetailTemplate from "../components/templates/project-detail-template/projectDetailTemplate";

const ProjectDetail = (props) => {
  return (
    <Layout title={`Dicar | Proyecto - ${props.pageContext.name}`}>
      <ProjectDetailTemplate {...props.pageContext}/>
    </Layout>
  )
}

export default ProjectDetail;