import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from '@contentful/rich-text-types';

import Anchor from "../../atoms/anchor/anchor";

import "./projectDetailTemplate.scss";

const Section = (props) => {
  const { title, desc, options = false } = props;

  return (
    <div className="t-project-detail__content__section">
      <h2 className="t-project-detail__content__section__title">
        {title}
      </h2>
      {options
        ?
        documentToReactComponents(desc, options)
        :
        <p className="t-project-detail__content__section__desc">
          {desc}
        </p>
      }
    </div>
  )
}

const ProjectDetailTemplate = (props) => {
  const {
    challenge,
    client,
    images,
    location,
    name,
    products,
    results,
    solution
  } = props;

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => (
        <p className="t-project-detail__content__section__desc">{children}</p>
      )
    }
  }

  return (
    <div className="t-project-detail">
      <h1 className="t-project-detail__title">
        {name}
      </h1>
      <div className="t-project-detail__content">
        <div className="t-project-detail__content__text">
          {client &&
            <Section title="Cliente" desc={client}/>
          }
          {location &&
            <Section title="Ubicación" desc={location}/>
          }
          {challenge &&
            <Section
              title="Desafío"
              desc={JSON.parse(challenge.raw)}
              options={options}
            />
          }
          {solution &&
            <Section
              title="Solución"
              desc={JSON.parse(solution.raw)}
              options={options}
            />
          }
          {results &&
            <Section
              title="Resultados"
              desc={JSON.parse(results.raw)}
              options={options}
            />
          }
          {products &&
            <Section
              title="Productos"
              desc={JSON.parse(products.raw)}
              options={options}
            />
          }
        </div>
        <div className="t-project-detail__content__images">
          {images.map((image) => (
            <img
              src={image.file.url}
              alt={image.file.fileName}
            />
          ))}
        </div>
      </div>
      <div className="t-project-detail__footer">
        <p className="t-project-detail__footer__text">
          ¿Tienes alguna pregunta sobre una aplicación o tienes un proyecto 
          similar?
          <br/>
          Llena nuestro formulario de contacto para hablar con tu representante
          de Dicar.
        </p>
        <Anchor
          className="t-project-detail__footer__button"
          href="/contacto"
        >
          Contáctenos
        </Anchor>
      </div>
    </div>
  )
}

export default ProjectDetailTemplate;